<template>
  <div id="users">
    <users-list></users-list>
  </div>
</template>

<script>
  import UsersList from './components/UsersList'

  export default{
    name: 'UsersView',
    components: {UsersList}
  }
</script>
