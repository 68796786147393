<template>
  <div id="users-list">
    <b-row>
      <b-col cols="12" class="mt-2">
        <b-card no-body class="mb-0">
          <div class="m-2">
            <b-overlay
              :show="overlayList"
              opacity="1.0"
              spinner-small
              spinner-type="grow"
              spinner-variant="primary"
              @hidden="_onHidden()">
              <div class="d-flex align-items-end justify-content-end">
                <div>
                  <b-overlay
                    :show="overlayForm"
                    opacity="0.5"
                    spinner-small
                    spinner-variant="primary"
                    class="d-inline-block"
                    @hidden="_onHidden()">
                    <b-button class="mr-1"
                      ref="button"
                      variant="primary"
                      :disabled="overlayForm"
                      @click.prevent="_exportUsers()"
                      v-if="permissions_visible.create_user"
                      >
                      <feather-icon
                        icon="DownloadIcon"
                        class="mr-50"/>
                      <span class="align-middle">
                        Descargar Usuarios
                      </span>
                    </b-button>
                  </b-overlay>
                </div>
                <b-button
                  variant="primary"
                  @click.prevent="_createUser()"
                  v-if="permissions_visible.create_user"
                  >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-50"/>
                  <span class="align-middle" >
                    Nuevo Usuario
                  </span>
                </b-button>
              </div>
              <div class="scroller mt-2 mb-1">
                <b-table
                  hover
                  striped
                  show-empty
                  class="position-relative mb-1"
                  :items="users.data"
                  :fields="tableColumns"
                  empty-text="Datos no Disponibles">
                  <template #cell(roles)="data">
                    {{ data.item.roles[0] ? data.item.roles[0].name : ''}}
                  </template>
                  <template #cell(status)="data">
                    <b-badge pill :variant="data.item.status == 1 ? 'light-success' : 'light-danger'">
                      {{ data.item.status == 1 ? 'Activado' : 'Desactivado' }}
                    </b-badge>
                  </template>
                  <template #cell(enabled-disabled)="data" v-if="permissions_visible.edit_users">
                    <b-form-checkbox
                      switch
                      inline
                      name="check-status"
                      v-model="data.item.status"
                      value="1"
                      unchecked-value="0"
                      @change="_changeStatus(data.item)">
                    </b-form-checkbox>
                  </template>
                  <template #cell(actions)="data">
                    <b-dropdown variant="link" no-caret
                      :right="$store.state.appConfig.isRTL">
                      <template #button-content>
                        <feather-icon size="16"
                          icon="MoreVerticalIcon"
                          class="align-middle text-body"/>
                      </template>
                      <b-dropdown-item @click.prevent="_auditUser(data.item)"
                      v-if="permissions_visible.audits_users"
                      >
                        <feather-icon icon="BookmarkIcon"/>
                        <span class="align-middle ml-50">Auditoria</span>
                      </b-dropdown-item>
                      <b-dropdown-item @click.prevent="_editUser(data.item)"
                        v-if="permissions_visible.edit_users"
                      >
                        <feather-icon icon="EditIcon" />
                        <span class="align-middle ml-50">Editar</span>
                      </b-dropdown-item>
                      <b-dropdown-item @click.prevent="_deleteUser(data.item)"
                      v-if="permissions_visible.delete_user">
                        <feather-icon icon="TrashIcon"  />
                        <span class="align-middle ml-50">Eliminar</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </template>
                </b-table>
              </div>
              <b-pagination
                  align="right"
                  v-model="currentPage"
                  hide-goto-end-buttons
                  :total-rows="users.total"/>
            </b-overlay>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import {mapActions, mapState} from 'vuex'
  import ToastificationContent from '@core/components/toastification/ToastificationContent'

  export default {
    name: 'UsersList',
    components: {},
    data () {
      return {
        permissions_visible:{
          create_user:false,
          edit_users:false,
          audits_users:false,
          delete_user:false,
        },
        overlayForm: false,
        overlayList: false,
        currentPage: 1,
        selectUser: [],
        tableColumns: [
          { key: 'id', sortable: false, label: 'ID'},
          { key: 'name', sortable: false, label: 'Nombre' },
          { key: 'last_name', sortable: false, label: 'Apellido' },
          { key: 'email', sortable: false, label: 'Email' },
          { key: 'roles', sortable: false, label: 'Rol' },
          { key: 'status', sortable: false, label: 'Estado' },
        ]
      }
    },
    beforeMount() {
      this._fetchUsers()
    },
    watch: {
      currentPage (val) {
        this._fetchUsers()
      }
    },
    computed: {
      ...mapState('users',['users']),
    },
    mounted(){
      this.permissions_visible = this.$PermissionHelper.checkPermissions(this.permissions_visible);
      if(this.permissions_visible.edit_users){
        this.tableColumns.push({ key: 'enabled-disabled', sortable: false, label: 'Activar/Desactivar' });
      }
      if(this.permissions_visible.edit_users){
        this.tableColumns.push({ key: 'actions', label: 'Acciones' },);
      } 

    },
    methods: {
      ...mapActions('users',['exportUsers','fetchUsers','deleteUsers','changeStatus']),
      _createUser() {
        this.$router.push({name: 'users-create'})
      },
      _editUser(user) {
        this.$router.push({ name: 'users-edit', params: { id: user.id, user: user} })
      },
      _auditUser(user) {
        this.$router.push({ name: 'users-audits', params: { id: user.id, audits: user } })
      },
      _deleteUser(user) {
        this.$swal({
          title: '¿Estás seguro que deseas eliminar este usuario?',
          text: 'La siguiente acción eliminará el usuario seleccionado.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Si',
          cancelButtonText: 'No',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this._confirmDelete(user);
          }
        })
      },
      async _exportUsers() {
        this.overlayForm = true
        await this.exportUsers()
        .then(res => {}).catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Problemas al exportar el archivo',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        }).finally(() => {
          this.overlayForm = false
        })
      },
      async _fetchUsers() {
        let data = { page: this.currentPage }
        this.overlayList = true
        await this.fetchUsers(data)
        .finally(() => {
          this.overlayList = false
        })
      },
      async _changeStatus(user) { 
        await this.changeStatus({id: user.id, status: user.status == 1})
        .then(res => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Estado modificado con éxito',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          })
        }).catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Problemas al modificar el estado',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        })
      },
      async _confirmDelete(user) {
        user['page'] = this.currentPage
        await this.deleteUsers(user)
        .then(res => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Usuario eliminado con éxito',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          })
        }).catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Problemas al eliminar el usuario',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        })
      },
      _onHidden() {
        this.$refs.button.focus()
      },
    },
  }
</script>

<style>
    .scroller {
        width: 100%;
        overflow-x: scroll;
        white-space:nowrap;
    }
</style>